.app-home {
  padding: 20px;
  max-width: 1200px;
  box-sizing: border-box;
  margin: auto;
}

.nav-bar {
  background: #1d152e;
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  z-index: 2;
  margin-right: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.nav-logo {
  height: 2rem;
  padding: 2px;
}

.footer {
  position: fixed;
  bottom: 5px;
  right: 0;
  left:0;
  margin:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111111;
  color: white;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.footer-text {
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  padding:10px;
}
.footer-width {
  width: 90%;
}

.playstore-button {
  width:150px;
  height:60px;
}

.appstore-button {
  width: 130px;
  height: 55px;
}


@media only screen and (max-width: 600px) {
  .footer-text {
    display:none;
  }
}


@media only screen and (min-width: 601px) {
  .font-text-size {
    font-size: 22px;
  }
}