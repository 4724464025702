@import url("https://fonts.googleapis.com/css?family=Rubik:regular,bold,italic&subset=latin,latin-ext");

body {
  padding-top: 75px;
  background-color: #f8f8f8;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  color: #111111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
