.block-img {
  float: left
}

.app-post {
  padding: 20px;
  max-width: 600px;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 15%
}

.pages > h1 {
  display: inline;
}

.duration {
  color: rgba(117, 117, 117, 1);
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
}

.inpsired_by {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}

.topics {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
}

.topic {
  color: #ffffff;
  background: #2d3134;
  border-radius: 5px;
  margin-left: 0px;
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 4px;
}

.highlights {
  background: #111111;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
}

.highlight_container {
  display: flex;
  flex-direction: column;
}

.highlight {
  display: flex;
  text-align: left;
  font-size: 14px;
  padding: 10px;
  color: #ffffff;
}

.external_video {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.external_link {
  padding-top: 10px;
  padding-bottom: 10px;
}

.audio_link {
  width: 560px;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.youtube_video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 5px;
  right: 0;
  left:0;
  margin:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111111;
  color: white;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.footer-text {
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  padding:10px;
}
.footer-width {
  width: 90%;
}

.playstore-button {
  width:150px;
  height:60px;
}

.appstore-button {
  width: 130px;
  height: 55px;
}

@media only screen and (max-width: 600px) {
  .embed_video * {
    max-width: 300px;
  }
  .card-details * {
    max-width: 100%;
  }
  .footer-text {
    display:none;
  }
}


@media only screen and (min-width: 601px) {
  .embed_video * {
    max-width: 600px;
  }
  .card-details * {
    max-width: 600px;
  }
  .font-text-size {
    font-size: 22px;
  }
}